@import './scss/base/font.scss';

$family-sans-serif: 'DINPro', sans-serif;
$family-monospace: 'DINPro', sans-serif;
$family-primary: 'DINPro', sans-serif;
$family-secondary: 'DINPro', sans-serif;
$family-code: 'DINPro', sans-serif;

//Colors
$primary: #1682C5;
$button-family: $family-primary;
$body-family: $family-primary;
$title-family: $family-primary;
$subtitle-family: $family-primary;
$code-family: $family-primary;
// $font-family: $button-family

//radius
$radius: 10px;
$control-radius: $radius;

// $primary: #ED1C24
// $primary-red-invert: findColorInvert($primary-red)

// @import "./scss/vendors/bulma/bulma.sass"
// @import "~react-bulma-components/src/index.sass"
