// Include Custom Fonts
@font-face {
  font-family: "DINPro";
  src: url("../../assets/fonts/DINPro-Light.eot?s5mzdn");
  src: url("../../assets/fonts/DINPro-Light.eot?s5mzdn#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/DINPro-Light.ttf?s5mzdn") format("truetype"),
    url("../../assets/fonts/DINPro-Light.woff?s5mzdn") format("woff"),
    url("../../assets/fonts/DINPro-Light.svg?s5mzdn#DINPro") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DINPro";
  src: url("../../assets/fonts/DINPro-Regular.eot?s5mzdn");
  src: url("../../assets/fonts/DINPro-Regular.eot?s5mzdn#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/DINPro-Regular.ttf?s5mzdn") format("truetype"),
    url("../../assets/fonts/DINPro-Regular.woff?s5mzdn") format("woff"),
    url("../../assets/fonts/DINPro-Regular.svg?s5mzdn#DINPro") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DINPro";
  src: url("../../assets/fonts/DINPro-Medium.eot?s5mzdn");
  src: url("../../assets/fonts/DINPro-Medium.eot?s5mzdn#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/DINPro-Medium.ttf?s5mzdn") format("truetype"),
    url("../../assets/fonts/DINPro-Medium.woff?s5mzdn") format("woff"),
    url("../../assets/fonts/DINPro-Medium.svg?s5mzdn#DINPro") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DINPro";
  src: url("../../assets/fonts/DINPro-Bold.eot?s5mzdn");
  src: url("../../assets/fonts/DINPro-Bold.eot?s5mzdn#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/DINPro-Bold.ttf?s5mzdn") format("truetype"),
    url("../../assets/fonts/DINPro-Bold.woff?s5mzdn") format("woff"),
    url("../../assets/fonts/DINPro-Bold.svg?s5mzdn#DINPro") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DINPro";
  src: url("../../assets/fonts/DINPro-Black.eot?s5mzdn");
  src: url("../../assets/fonts/DINPro-Black.eot?s5mzdn#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/DINPro-Black.ttf?s5mzdn") format("truetype"),
    url("../../assets/fonts/DINPro-Black.woff?s5mzdn") format("woff"),
    url("../../assets/fonts/DINPro-Black.svg?s5mzdn#DINPro") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src: url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,700&display=swap");
  font-weight: 700;
}

[class^="ic-"],
[class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "DINPro";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
